/* eslint-disable camelcase */
import {
    getInviteCode,
    commentList,
    postComment,
    redEnvelope,
    getUserInfoV2,
    desingerInfo,
    followUser,
    cancelFollowUser,
    publicPictures,
    myPins,
    historyPins,
    historyBoards,
    teamsInfo,
    teamsList,
    teamsInviteCode,
    teamsMemberList,
    CrossdoLogin,
    resetPassword,
    sendCode,
    registerValidateCode,
    teamInviteDetail,
    teamInviteJoin,
    editTeamName,
    removeTeam, editMemeberRole, removeTeamMember, teamsMemberPrice, getCaseList, checkTokenValid,
    modelPreviewFile, modelByImg
} from "@constants/servlet" ;
import { ServerPService } from "@constants/servers";
import axios, { AxiosResponse } from 'axios' ;
import qs from 'qs' ;
import { getToken } from "@scripts/tools" ;

export const generateInviteCode = async (ops: { uid: string | number }): Promise<string> => {
    try {
        const res = await axios({
            url: getInviteCode,
            params: {
                uid: ops.uid
            }
        });
        if ( res.status === 200 ) {
            return res.data;
        }
        throw res;
    } catch ( e ) {
        throw e;
    }
};

/**
 * @description 提交评论
 * @param ops
 */

export const toPostComment = async (ops: { img_id: number, content: string }) => {
    try {
        const res = await axios({
            url: postComment,
            method: `POST`,
            headers: {
                'Content-Type': `application/x-www-form-urlencoded; charset=UTF-8`
            },
            data: qs.stringify({
                token: await getToken(),
                img_id: ops.img_id,
                content: ops.content
            })
        });
        if ( res.status === 200 ) {
            return res.data;
        }
        throw res;
    } catch ( e ) {
        throw e;
    }
};
/**
 * @description 获取评论列表
 * @param ops
 */
export const getCommentList = async (ops: { img_id: number, page: number }) => {
    try {
        const res = await axios({
            method: `POST`,
            url: commentList,
            headers: {
                'Content-Type': `application/x-www-form-urlencoded; charset=UTF-8`
            },
            data: qs.stringify({
                token: await getToken(),
                img_id: ops.img_id,
                page: ops.page
            })
        });
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data;
        }
        throw res;
    } catch ( e ) {
        throw e;
    }
};
/**
 * @description 获取弹框数据
 */
export const getRedEnvelopeData = async () => {
    try {
        const res = await axios({
            url: redEnvelope,
            method: `POST`,
            data: qs.stringify({ user_token: await getToken() })
        });
        if ( res.data.code === 200 ) {
            return res.data.data;
        }
        throw res;
    } catch ( e ) {
        throw e;
    }
};
/**
 * @default 获取用户数据 名称.关注数.粉丝数等
 * @param ops
 */
export const getUserInfo = async (ops: { uid?: string, uuid?: string }): Promise<AxiosResponse> => {
    try {
        const res = await axios({
            url: getUserInfoV2,
            params: {
                uid: ops.uid,
                uuid: ops.uuid,
                user_token: await getToken()
            }
        });
        if ( res.status === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { code: 500 });
    } catch ( e ) {
        throw e;
    }
};
/**
 * @default 获取用户设计师认证信息
 * @param ops
 */
export const getDesingerInfo = async (ops: { uid?: string, uuid?: string }): Promise<AxiosResponse> => {
    try {
        const res = await axios({
            url: desingerInfo,
            params: {
                uid: ops.uid,
                uuid: ops.uuid,
                user_token: await getToken()
            }
        });
        if ( res.status === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};
/**
 * @description 关注用户
 * @param ops
 */
export const toFollowUser = async (ops: { fans_id?: string, fans_uuid?: string }): Promise<AxiosResponse> => {
    try {
        const res = await axios({
            url: followUser,
            method: `POST`,
            headers: {
                'Content-Type': `application/x-www-form-urlencoded; charset=UTF-8`
            },
            data: qs.stringify({
                fans_id: ops.fans_id,
                fans_uuid: ops.fans_uuid,
                user_token: await getToken()
            })
        });
        if ( res.status === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { code: 500 });
    } catch ( e ) {
        throw e;
    }
};
export const toCancelFollowUser = async (ops: { fans_id?: string, fans_uuid?: string }): Promise<AxiosResponse> => {
    try {
        const res = await axios({
            url: cancelFollowUser,
            method: `POST`,
            headers: {
                'Content-Type': `application/x-www-form-urlencoded; charset=UTF-8`
            },
            data: qs.stringify({
                fans_id: ops.fans_id,
                fans_uuid: ops.fans_uuid,
                user_token: await getToken()
            })
        });
        if ( res.status === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { code: 500 });
    } catch ( e ) {
        throw e;
    }
};
/**
 * @description 获取我的图片列表
 * @param ops
 */
export const getPublicPictures = async (ops: { page: number, rows: number, min_id?: number }) => {
    try {
        const res = await axios({
            url: publicPictures,
            params: {
                user_token: await getToken(),
                page: ops.page,
                rows: ops.rows,
                min_id: ops.min_id
            }
        });
        if ( res.status === 200 ) {
            return res.data;
        }
    } catch ( e ) {
        throw e;
    }
};
/**
 * @description 获取我收藏的案例列表
 * @param ops
 */
export const getPublicCase = async (ops: { page: number, rows: number }) => {
    try {
        const res = await axios({
            url: getCaseList,
            params: {
                token: await getToken(),
                page: ops.page,
                pageSize: ops.rows
            }
        });
        console.log(res)
        if ( res.status === 200 ) {
            return res.data;
        }
    } catch ( e ) {
        throw e;
    }
};
/**
 * @description 搜索我的图片
 * @param ops
 */
export const searchMyPins = async (ops: { page: number, rows: number, words: string }) => {
    try {
        const res = await axios({
            url: myPins,
            params: {
                token: await getToken(),
                words: ops.words,
                page: ops.page,
                rows: ops.rows
            }
        });
        return res.data;
    } catch ( e ) {
        throw e;
    }
};
export const getHistoryBoards = async (ops: { page: number }) => {
    try {
        const res = await axios({
            url: historyBoards,
            params: {
                p: ops.page,
                token: await getToken()
            }
        });
        if ( res.status === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};

export const getHistoryPins = async (ops: { page: number }) => {
    try {
        const res = await axios({
            url: historyPins,
            params: {
                p: ops.page,
                token: await getToken()
            }
        });
        if ( res.status === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};

/**
 * @default 获取用户团队信息
 * @param token
 */
export const getTeamsList = async () => {
    try {
        const res = await axios({
            url: teamsList,
            params: {
                token: await getToken()
            }
        });
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};
/**
 * @default 获取用户团队详情
 * @param ops token enterprise_id （团队ID）
 */
export const getTeamsDetail = async (ops: { id: number, uuid: string }) => {
    try {
        const res = await axios({
            url: teamsInfo,
            params: {
                token: await getToken(),
                enterprise_id: ops.id || '',
                uuid: ops.uuid
            }
        });
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};
/**
 * @default 获取邀请成员链接
 * @param ops token enterprise_id（团队ID）
 */
export const getTeamsInviteCode = async (ops: { id: number }) => {
    try {
        const res = await axios({
            url: teamsInviteCode,
            params: {
                token: await getToken(),
                enterprise_id: ops.id
            }
        });
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};
/**
 * @default 修改团队名称
 * @param ops token enterprise_id（团队ID） name 团队名称
 */
export const editTeamNameApi = async (ops: { id: number, name: string }) => {
    try {
        const res = await axios({
            url: editTeamName,
            method: `POST`,
            params: {
                token: await getToken(),
                enterprise_id: ops.id,
                name: ops.name
            }
        });
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};

/**
 * @default 编辑成员角色
 * @param ops token enterprise_id（团队ID） name 团队名称
 */
export const editMemberRole = async (ops: { id: number, userid: number, role: number }) => {
    try {
        const res = await axios({
            url: editMemeberRole,
            method: `POST`,
            params: {
                token: await getToken(),
                enterprise_id: ops.id,
                userid: ops.userid,
                role: ops.role
            }
        });
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};
/**
 * @default 移交团队
 * @param ops  token enterprise_id（团队ID） userid 用户ID（要移交的用户ID）
 */
export const removerTeam = async (ops: { id: number, userid: number }) => {
    try {
        const res = await axios({
            url: removeTeam,
            method: `POST`,
            params: {
                token: await getToken(),
                enterprise_id: ops.id,
                userid: ops.userid
            }
        });
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};
/**
 * @default 移除成员
 * @param ops token enterprise_id（团队ID） userid 要移除的团队成员ID  to_userid 资源移交人(被移除人的图片集)
 */
export const removerTeamMember = async (ops: { id: number, userid: number, to_userid: number }) => {
    try {
        const res = await axios({
            url: removeTeamMember,
            method: `POST`,
            params: {
                token: await getToken(),
                enterprise_id: ops.id,
                userid: ops.userid,
                to_userid: ops.to_userid
            }
        });
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};
/**
 * @default 获取团队成员列表
 * @param ops
 */
export const getTeamsMemberList = async (ops: { id: number, uuid: string }) => {
    try {
        const res = await axios({
            url: teamsMemberList,
            params: {
                token: await getToken(),
                enterprise_id: ops.id || '',
                uuid: ops.uuid
            },
            method: `POST`
        });
        console.log('aaaaaaaaaaaaa', res)
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};
/**
 * @default 获取团队邀请详情
 * @param ops
 * @param token （ 可无 ）
 * @param code (邀请码)
 */
export const getTeamIniviteDetail = async (ops: { code: number }) => {
    try {
        const res = await axios({
            url: teamInviteDetail,
            params: {
                code: ops.code
            },
            method: `POST`
        });
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};
/**
 * @default 获取成员额度扩容价格
 * @param ops
 * @param token
 */
export const getMemberPrice = async () => {
    try {
        const res = await axios({
            url: teamsMemberPrice,
            params: {
                token: await getToken()
            }
        });
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};
/**
 * @default 用户根据分享链接中的code加入团队
 * @param token （ 可无 ）
 * @param code (邀请码)
 */
export const getTeamIniviteJoin = async (ops: { code: number }) => {
    try {
        const token = await getToken();
        const form = new FormData();
        form.append(`token`, token);
        // @ts-ignore
        form.append(`code`, ops.code);
        const res = await axios({
            url: teamInviteJoin,
            method: `POST`,
            data: form
        });
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};

/**
 * @default 跨平台统一登录
 * @param  phone 登录手机号
 * @param  password 登录密码
 */
export const doUniteLogin = async (ops: { phone: string, password: string }) => {
    try {
        const res = await axios({
            url: CrossdoLogin,
            params: {
                phone: ops.phone,
                password: ops.password
            },
            method: `POST`,
        });
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};

/**
 * @description 发送短信验证码
 */
export const sendVCode = async (ops: { phone: string, imgCode: string }) => {
    try {
        const res = await axios({
            url: sendCode,
            params: {
                account: ops.phone,
                codeType: 1,
                imgcode: ops.imgCode
            },
            method: `POST`,
        });
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};


/**
 * @description 校验手机验证码正确性
 */
export const validateCode = async (ops: { phone: string, vCode: string }) => {
    try {
        const res = await axios({
            url: registerValidateCode,
            params: {
                phone: ops.phone,
                code: ops.vCode
            },
            method: `POST`,
        });
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};
/**
 * @description 重置密码
 */
export const resetPassData = async (ops: { phone: string, password: string, vCode: string }) => {
    try {
        const res = await axios({
            url: resetPassword,
            params: {
                account: ops.phone,
                isPhone: 1,
                code: ops.vCode,
                password: ops.password.replace(/\s+/gm, '')
            },
            method: `POST`
        });
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};

/**
 * @description 更新用户名
 */
export const updateUserName = async (ops: { name: string, token: string }) => {
    try {
        const res = await axios({
            url: ServerPService + `/update-info-name.htm`,
            params: {
                name: ops.name,
                user_token: ops.token
            }
        });
        if ( res.status === 200 && res.data.code === '1' ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};
// auth/update-user-avatar.htm
/**
 * @description 更新头像
 */
export const updateUserAvatar = async (data) => {
    try {
        const res = await axios({
            url: ServerPService + `/auth/update-user-avatar.htm`,
            method: `POST`,
            data
        });
        if ( res.status === 200 ) {
            return res.data;
        }
        return Object.assign(res.data, { status: 500 });
    } catch ( e ) {
        throw e;
    }
};
export const getModelPreviewFile = async ( uuid: string ) => {
    try {
        const res = await axios ({
            url: modelPreviewFile,
            params: {
                uuid
            }
        } )
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data ;
        }
        return { code: 500 } ;
    } catch ( e ) {
        console.log(e)
    }
}
export const generate3D = async ( params ) => {
    try {
        const formData = new FormData();
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                formData.append(key, params[key]);
            }
        }
        const res = await axios ({
            url: modelByImg,
            method: `POST`,
            data: formData
        } )
        console.log ( res, 'generate3D')
        if ( res.status === 200 ) {
            return res.data.data ;
        }
        return { code: 500 } ;
    } catch ( e ) {
        console.log(e)
    }
}
